import type { ProductFormulationDetails } from '@/api/product/getProductDetails.types';

export interface ProductDescriptionCardProps {
  id: number;
  name: string;
  image: string;
  supplier: string;
  price?: number;
  discountPrice?: number;
  spectrumId?: number;
  size?: string;
  formulation?: ProductFormulationDetails;
  summary?: string;
  description?: string;
  strain?: string;
  category?: number;
  terpeneProfile?: string;
  cbdThcRatio?: string;
  schedule?: number;
  conditions?: string;
  howToUse?: string;
  showCloseIcon?: boolean;
  isConcessionProduct?: boolean;
  thcStrengthForDisplay?: string;
  cbdStrengthForDisplay?: string;
  onClose?: () => void;
}

export enum ProductSpectrum {
  Broad = 'Broad',
  Full = 'Full'
}
