import type { TransferListItem } from '@montugroup/design-system';
import { findIntersection, removeItemsByIntersection } from '@montugroup/design-system';
import iziToast from 'izitoast';
import { useEffect, useState } from 'react';

const useTransferList = (leftSideArray: TransferListItem[], rightSideArray: TransferListItem[]) => {
  const [checkedItems, setCheckedItems] = useState<TransferListItem[]>([]);
  const [leftSideItems, setLeftSideItems] = useState<TransferListItem[]>([]);
  const [rightSideItems, setRightSideItems] = useState<TransferListItem[]>([]);

  useEffect(() => {
    if (!leftSideItems?.length && !rightSideItems?.length) {
      setLeftSideItems(leftSideArray);
      setRightSideItems(rightSideArray);
    }
  }, [leftSideArray, leftSideItems, rightSideArray, rightSideItems]);

  const formatMoveAllMessage = (direction: string) => {
    return `Are you sure you want to move all items to the ${direction} side?`;
  };

  const handleAlertMessage = (message: string, handleCallback: () => void) => {
    iziToast.question({
      id: 'transfer-action-message',
      timeout: false,
      displayMode: 1,
      zindex: 999,
      overlay: true,
      message: message,
      position: 'center',
      buttons: [
        [
          '<button><b>Yes</b></button>',
          function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'Yes');
          },
          true
        ],
        [
          '<button>No</button>',
          function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'No');
          },
          true
        ]
      ],
      onClosed: (_instance, _toast, closedBy) => {
        if (closedBy === 'Yes') {
          handleCallback();
        }
      }
    });
  };

  const handleMoveAllLeftOnClick = () => {
    handleAlertMessage(formatMoveAllMessage('left'), moveAllLeft);
  };

  const moveAllLeft = () => {
    setLeftSideItems(leftSideItems.concat(rightSideItems));
    setRightSideItems([]);
    setCheckedItems([]);
  };

  const handleMoveAllRightOnClick = () => {
    handleAlertMessage(formatMoveAllMessage('right'), moveAllRight);
  };

  const moveAllRight = () => {
    setLeftSideItems([]);
    setRightSideItems(rightSideItems.concat(leftSideItems));
    setCheckedItems([]);
  };

  const handleMoveCheckedLeftOnClick = () => {
    const rightChecked = findIntersection(checkedItems, rightSideItems);
    setLeftSideItems(leftSideItems.concat(rightChecked));
    setRightSideItems(removeItemsByIntersection(rightSideItems, rightChecked));
    setCheckedItems([]);
  };

  const handleMoveCheckedRightOnClick = () => {
    const leftChecked = findIntersection(checkedItems, leftSideItems);
    setLeftSideItems(removeItemsByIntersection(leftSideItems, leftChecked));
    setRightSideItems(rightSideItems.concat(leftChecked));
    setCheckedItems([]);
  };

  const handleItemOnClick = (item: TransferListItem) => {
    const currentIndex = checkedItems.indexOf(item);
    const updatedCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      updatedCheckedItems.push(item);
    } else {
      updatedCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(updatedCheckedItems);
  };

  return {
    checkedItems,
    leftSideItems,
    rightSideItems,
    handleMoveAllLeftOnClick,
    handleMoveAllRightOnClick,
    handleMoveCheckedLeftOnClick,
    handleMoveCheckedRightOnClick,
    handleItemOnClick
  };
};

export default useTransferList;
