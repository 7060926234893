import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { Box, Card, Grid, Link, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';

import { FF_ENABLE_DISCOUNT_CODES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import useConsultationPrice from '@/hooks/consult/useConsultationPrice';
import type { CalendarData } from '@/hooks/rest/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/types/tracking.types';
import { GTM_EVENTS } from '@/utils/gtmEvents';

import PreConsultButton from './PreConsultButton';

const SummaryHeading = styled(Typography)({
  fontWeight: 600,
  marginBottom: 16
}) as typeof Typography;

const SummaryItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 4
});

const StyledCard = styled(Card)(({ theme }) => ({
  padding: '2rem',
  borderRadius: '20px',
  backgroundColor: theme.palette.pillCard.main,
  display: 'flex',
  flexDirection: 'column'
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 20,
    marginLeft: 0,
    width: '100%'
  },

  marginTop: 0
}));

export type PaymentSuccessProps = { data: CalendarData };

export default function PaymentSuccess({ data }: PaymentSuccessProps) {
  const { flags } = useFeatureFlags();

  const ffEnableDiscountCodes = flags[FF_ENABLE_DISCOUNT_CODES];
  const { formattedConsultationPrice } = useConsultationPrice();
  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();

  const consultation = ffEnableDiscountCodes ? JSON.parse(localStorage.getItem('consultation_summary') || '{}') : {};

  const isSuccessTrackingTriggered = useRef<boolean>(false);

  const nurseFirstNameOnly = (data?.assignedTo || '').split(' ')[0];

  useEffect(() => {
    if (!isSuccessTrackingTriggered.current) {
      const trackingData = {
        ...JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}'),
        payment_value: parseInt(consultation?.totalAmount?.replace(/\$/g, ''))
      };

      sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_SUBMIT, {
        id: GoogleAnalyticsEventId.PAYMENT_DETAILS_SUBMIT,
        status: 'Successful',
        ...trackingData
      });

      isSuccessTrackingTriggered.current = true;
    }
  }, [sendGoogleAnalyticsEvent, consultation?.totalAmount]);

  const summaryContent = (
    <>
      <SummaryHeading
        component="h2"
        fontWeight={600}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        fontSize={'1.2rem'}
      >
        <TaskAltOutlinedIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
        Booking confirmation
      </SummaryHeading>
      <Typography marginBottom={8}>
        Your appointment has been confirmed. A confirmation email has been sent to <strong>{data?.email}</strong>
      </Typography>
      <SummaryHeading component="h3">Appointment Summary</SummaryHeading>
      <SummaryItem>
        <Typography>Appointment nurse</Typography>
        <Typography>{nurseFirstNameOnly}</Typography>
      </SummaryItem>
      {data?.startTime && (
        <SummaryItem>
          <Typography>Date & Time</Typography>
          <Typography>{new Date(data?.startTime).toLocaleString()}</Typography>
        </SummaryItem>
      )}
      {consultation?.discountText && (
        <SummaryItem color="success.main" marginY={8}>
          <Typography fontWeight={600} display="flex" alignItems="center" flexWrap="wrap">
            <DiscountOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
            {consultation?.discountText}
          </Typography>
          <Typography fontWeight={600}>{consultation?.discountAmount}</Typography>
        </SummaryItem>
      )}
      <hr />
      <SummaryItem marginTop={4}>
        <SummaryHeading>Consultation amount</SummaryHeading>
        <SummaryHeading>{consultation?.totalAmount || formattedConsultationPrice}</SummaryHeading>
      </SummaryItem>
    </>
  );

  return (
    <>
      {/*
        TODO - Below code with `vwo class` is related to Payment flow - AB Test
        VWO experiment details here: https://app.vwo.com/#/test/ab/70/edit/pages/
        AB Test Ticket: https://montugroup.atlassian.net/browse/B2C-4673
        AB Test Removal Ticket: https://montugroup.atlassian.net/browse/B2C-4893
        */}
      <Box className="vwo-ab-test-control-variant">
        <StyledGridContainer
          container
          rowSpacing={{ xs: 5, sm: 20 }}
          columnSpacing={{ xs: 0, sm: 8 }}
          paddingTop={{ xs: 0, sm: 0 }}
          position={'relative'}
          mt={5}
        >
          <Grid item xs={12} md={6}>
            {summaryContent}
          </Grid>
          <Grid item xs={12} md={6} paddingTop={{ xs: 100, sm: 0 }}>
            <StyledCard>
              <Typography component="h2" fontSize={'1.3rem'} paddingBottom={5} fontWeight={600}>
                One final step
              </Typography>
              <Typography>
                To help us create your personalised treatment plan, we need some details about your condition and
                medical history. This should take around 5 minutes to complete.
              </Typography>
              <PreConsultButton gtmEventId={GTM_EVENTS.COMPLETE_PRE_CONSULT_SUCCESS} />
              <Typography textAlign="center">
                Need help? <Link href="tel:1800864878">Contact us.</Link>
              </Typography>
            </StyledCard>
          </Grid>
        </StyledGridContainer>
        <Alert variant="outlined" severity="info" sx={{ marginTop: 8 }}>
          <strong>No-Show Policy:</strong>
          We request at least 24 hours notice if you need to cancel or reschedule your appointment. Charges may apply
          for cancellation after this time. Appointments are in high demand and this policy helps ensure better
          availability for our patients.
        </Alert>
      </Box>
      <Box className="vwo-ab-test-treatment-variant">
        <StyledGridContainer
          container
          rowSpacing={{ xs: 5, sm: 20 }}
          columnSpacing={{ xs: 0, sm: 8 }}
          paddingTop={{ xs: 0, sm: 10 }}
          position={'relative'}
          mt={5}
        >
          <Grid item md={12}>
            <SummaryHeading
              component="h2"
              fontWeight={600}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              fontSize={'1.6rem'}
            >
              <TaskAltOutlinedIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
              Payment details saved
            </SummaryHeading>
            <Typography marginBottom={8}>
              Charges will <strong>only</strong> apply after an eligible nurse consultation.
            </Typography>
            <StyledCard>
              <Typography component="h2" fontSize={'1.3rem'} paddingBottom={5} fontWeight={600}>
                One final step
              </Typography>
              <Typography>
                Complete the pre-consultation form below to help our nurses and doctors properly assess your condition.
                This should take around 5 minutes.
              </Typography>
              <PreConsultButton gtmEventId={GTM_EVENTS.COMPLETE_PRE_CONSULT_SUCCESS} />
            </StyledCard>
          </Grid>
        </StyledGridContainer>
        <Alert variant="outlined" severity="info" sx={{ marginTop: 8, marginBottom: 15 }}>
          <strong>Notice Policy:</strong> 24-hour advance notice is required for cancellations or rescheduling. Late
          changes may incur a fee. This supports better access for all patients.
        </Alert>
      </Box>
    </>
  );
}
