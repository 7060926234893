import { AlertModal } from '@/shared-ui';
import { DEFAULT_NOTICE_OF_TREATMENT_MODAL_PROPS } from './NoticeOfTreatmentModal.config';
import { pdf as placeholder } from './NoticeOfTreatmentModal.mock';
import { Viewer } from './NoticeOfTreatmentModal.styles';

export type NoticeOfTreatmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  pdf: string;
};

const NoticeOfTreatmentModal = ({ isOpen, onClose, onConfirm, pdf = placeholder }: NoticeOfTreatmentModalProps) => (
  <AlertModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} {...DEFAULT_NOTICE_OF_TREATMENT_MODAL_PROPS}>
    <Viewer src={`${pdf}#toolbar=0`} title="Notification of Treatment Form" />
  </AlertModal>
);

export default NoticeOfTreatmentModal;
