import { Button, Grid } from '@mui/material';

import settings from '../../data/constants';

import '../../assets/scss/DassForm/dassAssessmentScoreStatus.scss';

const DassAssessmentScoreStatus = (props) => {
  const { dassAssessmentDetails, dassAssessmentScoreDetail, handleResendEmail, isDoc, disabled } = props;

  const renderResendEmail = () => {
    let button = null;
    if (!isDoc) {
      button = (
        <Button data-testid="resend-email" className="resend-email" disabled={disabled} onClick={handleResendEmail}>
          Resend E-mail
        </Button>
      );
    }
    return button ? <div>{button}</div> : '';
  };

  return (
    <>
      <h4 style={{ marginBottom: '0.3rem' }}>DASS Assessment Score</h4>
      <Grid container className="dassScoreStatusContainer">
        {!dassAssessmentDetails?.is_submitted && (
          <Grid item xs={7} className="greyColor" data-testid="yet_to_be_completed">
            Yet to be Completed {renderResendEmail()}
          </Grid>
        )}
        <Grid item xs={5}></Grid>
        {(dassAssessmentDetails?.is_submitted || dassAssessmentScoreDetail?.anxiety?.level) && (
          <Grid item xs={7} data-testid="score">
            <Grid container className="greyColor">
              <Grid item xs={6} className="dass-score-content">
                Depression
              </Grid>
              <Grid item xs={6} className="textAlignEnd">
                {dassAssessmentScoreDetail?.depression?.score}
              </Grid>
              <Grid item xs={6} className="dass-score-content">
                Anxiety
              </Grid>
              <Grid item xs={6} className="textAlignEnd">
                {dassAssessmentScoreDetail?.anxiety?.score}
              </Grid>
              <Grid item xs={6} className="dass-score-content">
                Stress
              </Grid>
              <Grid item xs={6} className="textAlignEnd">
                {dassAssessmentScoreDetail?.stress?.score}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DassAssessmentScoreStatus;
