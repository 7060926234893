import type { PrescribedProductMetadataSchema, TableColumnDefinitionSchema } from '@montugroup/prescription-contracts';
import {
  MedicationPrescriptionStatus,
  PrescribedMedicationDossierItemSchema,
  PrescribedMedicationSchema
} from '@montugroup/prescription-contracts';
import { VisibilityOutlined } from '@mui/icons-material';
import { Box, IconButton, Radio, Stack, Typography } from '@mui/material';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import type { MouseEvent } from 'react';
import type { z } from 'zod';

import StatusChip from '../../../../shared-ui/StatusChip/StatusChip';

import { Logger } from '@/utils/logger';
import { getProductDisplayName } from '../PrescriptionPad/PrescriptionPad.utils';
import type { RenderScriptLinkCellOptions } from './PrescribedMedicationsTabsTable.types';

const logger = new Logger('PrescribedMedicationsTabsTable');

export const AllPrescribedMedicationAccessorKeyEnum = PrescribedMedicationSchema.merge(
  PrescribedMedicationDossierItemSchema
).keyof();

export type AllPrescribedMedicationAccessorKey = z.infer<typeof AllPrescribedMedicationAccessorKeyEnum>;

export const ROWS_PER_PAGE_MULTIPLE = 5;

/**
 * Get color by prescription status
 * @param status - prescription state
 * @returns - prescription status code
 */
export const getStatusColor = (status?: MedicationPrescriptionStatus) => {
  switch (status) {
    case MedicationPrescriptionStatus.Active:
      return 'success';

    case MedicationPrescriptionStatus.Cancelled:
      return 'error';

    case MedicationPrescriptionStatus.Expired:
    default:
      return 'default';
  }
};

/**
 * Render the status chip cell
 * @param params.value - this should be the `status` text as this is what this cell is used for
 * @returns the status chip
 */
export const renderStatusChipCell = (statusLabel: MedicationPrescriptionStatus) => (
  <Box minWidth={'5rem'}>
    <StatusChip label={capitalize(statusLabel)} size="small" color={getStatusColor(statusLabel)} showIcon={false} />
  </Box>
);

/**
 * Render the product details cell
 * @param params.value - the value should be a `PrescribedProductMetadataSchema` type, as that's what this render cell is used for
 * @returns the product details
 */
export const renderProductDetailsCell = (metadata: PrescribedProductMetadataSchema) => (
  <Stack>
    <Typography variant="body2">{`${metadata?.supplierName} | ${metadata?.productName}`}</Typography>
    <Typography variant="body2">{metadata?.description}</Typography>
  </Stack>
);

export const renderViewScriptCell = (
  { getValue }: CellContext<PrescribedMedicationSchema, PrescribedMedicationDossierItemSchema[]>,
  onViewDossierClick?: (dossier: PrescribedMedicationDossierItemSchema[], event: MouseEvent<HTMLElement>) => void
) => {
  // if there's a product ID AND the ID is already prescribed, the button should be disabled.
  return (
    <Stack direction="row" gap={5} justifyContent="flex-end" width="100%">
      <IconButton
        size="small"
        color="warning"
        aria-label="view script"
        onClick={(event) => onViewDossierClick?.(getValue(), event)}
      >
        <VisibilityOutlined />
      </IconButton>
    </Stack>
  );
};

/**
 * Render the reissue link cell
 * @param params - Grid Cells params. We will use `row` here to access the full object, as there are additional information needed
 * @returns the reissue link
 */
export const renderReissueLinkCell = (
  medicationItem: PrescribedMedicationSchema,
  { onReissueClick, itemsToReissue = [] }: Pick<RenderScriptLinkCellOptions, 'onReissueClick' | 'itemsToReissue'>
) => {
  const { reissue, product, status } = medicationItem;

  if (!reissue) {
    return null;
  }

  const shouldDisable = reissue?.isDisabled;
  // const tooltipMessage = isInPrescriptionPad ? 'This medication is already set to be reissued' : reissue?.tooltip;
  // const isRadioSelected =
  //   reissue?.linkAttributes?.productId === undefined
  //     ? false
  //     : selectedReissueProductIds[reissue?.linkAttributes?.productId];

  // check if the productId is in the selectedReissueProductIds object
  // Determine if the radio button should be selected
  const productId = reissue?.linkAttributes?.productId;
  const isRadioSelected = !!productId && itemsToReissue.some((item) => item.medicationPadItem?.productId === productId);

  return (
    <Box>
      <Radio
        color="secondary"
        name="Reissue"
        disabled={shouldDisable}
        checked={isRadioSelected}
        onClick={() => onReissueClick(reissue.linkAttributes!, product, status)}
      />
    </Box>
  );
};

/**
 * Render the cancel link cell
 * @param params - medicationItem to get the status and id of the medication. onClickCancel is the function called when clicking this button.
 * @returns the cancel link
 */
export const renderCancelLinkCell = (
  medicationItem: PrescribedMedicationSchema,
  { onClickCancel, itemsToCancel = [] }: Pick<RenderScriptLinkCellOptions, 'onClickCancel' | 'itemsToCancel'>
) => {
  const {
    product: { description, productName, supplierName },
    cancel,
    reissue
  } = medicationItem;

  const displayName = getProductDisplayName(productName, description, supplierName);

  const productId = reissue?.linkAttributes?.productId;

  const isRadioSelected = !!productId && itemsToCancel.some((item) => item.productId === productId);

  if (!productId) {
    logger.error('Product ID is missing from the reissue object', { reissue });
    return;
  }

  return (
    <Box>
      <Radio
        color="secondary"
        name="Cancel"
        disabled={!cancel?.isCancellable}
        checked={isRadioSelected}
        onClick={() => onClickCancel({ productId, displayName })}
      />
    </Box>
  );
};

// return created date column formatting config
export const renderCreatedDateColumn = (createdDate: string) => {
  const dateValue = +new Date(createdDate);
  const formattedDate = format(dateValue, 'dd/MM/yyyy');

  return formattedDate;
};

const getTableConfigByKey = <TData extends PrescribedMedicationSchema | PrescribedMedicationDossierItemSchema>(
  key: AllPrescribedMedicationAccessorKey,
  cellOptions?: RenderScriptLinkCellOptions
): Partial<ColumnDef<TData>> | void => {
  if (key === 'status') {
    return {
      enableSorting: true,
      cell: (item) => renderStatusChipCell(item.getValue() as MedicationPrescriptionStatus)
    };
  }

  if (key === 'product') {
    return {
      enableSorting: false,
      cell: (item) => renderProductDetailsCell(item.getValue() as PrescribedProductMetadataSchema)
    };
  }

  if (key === 'createdDate') {
    return {
      enableSorting: false,
      cell: (item) => renderCreatedDateColumn(item.getValue() as string)
    };
  }

  if (key === 'dossier' && cellOptions?.onViewDossierClick) {
    return {
      enableSorting: false,
      meta: { style: { textAlign: 'right', width: 80 } },
      cell: (item) => {
        return renderViewScriptCell(
          // this cast is safe because if the key `dossier` will only return PrescribedMedicationSchema's data
          item as unknown as CellContext<PrescribedMedicationSchema, PrescribedMedicationDossierItemSchema[]>,
          cellOptions?.onViewDossierClick
        );
      }
    };
  }

  // need to keep this config separate
  if (key === 'reissue' && cellOptions) {
    return {
      enableSorting: false,
      accessorFn: (row) => row,
      meta: { style: { paddingRight: 25 } },
      cell: (item) => {
        const cellValue = item.getValue() as PrescribedMedicationSchema;
        return renderReissueLinkCell(cellValue, cellOptions);
      }
    };
  }

  if (key === 'cancel' && cellOptions) {
    return {
      enableSorting: false,
      accessorFn: (row) => row,
      meta: { style: { paddingRight: 25 } },
      cell: (item) => {
        const cellValue = item.getValue() as PrescribedMedicationSchema;
        return renderCancelLinkCell(cellValue, cellOptions);
      }
    };
  }
};

/**
 * format table columns as per
 * https://tanstack.com/table/latest/docs/guide/column-defs
 * @param headings - the headings passed from API
 * @param cellOptions - the reissue cell options
 * @returns - table config compatible with ColumnDef doc
 */
export const formatTableColumns = <TData extends PrescribedMedicationSchema | PrescribedMedicationDossierItemSchema>(
  headings: TableColumnDefinitionSchema[],
  cellOptions?: RenderScriptLinkCellOptions
): ColumnDef<TData>[] => {
  const columnDefs = headings.map((heading) => {
    const validateHeadingField = AllPrescribedMedicationAccessorKeyEnum.safeParse(heading.field);
    if (!validateHeadingField.success) {
      return null;
    }

    const accessorKey = heading.field as AllPrescribedMedicationAccessorKey;
    return {
      accessorKey,
      header: heading.headerName,
      ...(getTableConfigByKey(accessorKey, cellOptions) || {})
    };
  });

  return columnDefs.filter(Boolean) as ColumnDef<TData>[];
};
