import type { ConsultationFields } from '@/components/PatientPageV2/ConsultationTab.types';
import {
  IhiStatus,
  getIHINumberFromUrl,
  getIhiStatus
} from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/IhiVerifyAndUpdateModals.utils';
import { usePostPatientIhi } from '@/hooks/patients/usePostPatientIhi';
import { useVerifyPatientIhi } from '@/hooks/patients/useVerifyPatientIhi';
import type { ExtendedSnackbarProps } from '@/shared-ui';
import type { GetPatientIhiResponseDto, UpdatePatientIhiBodyDto } from '@montugroup/pms-api-contracts';
import iziToast from 'izitoast';
import { useState } from 'react';

type UsePatientIHIProps = {
  patientData: ConsultationFields;
  patientId: string;
  handleAlerts: (snackbarProps: ExtendedSnackbarProps) => void;
  setData: (value: ConsultationFields) => void;
  getPatientData: () => void;
};

export interface ModalParamsSelectMultipleFields {
  patientVerifyIhiResponseData: PatientVerifyIhiResponseData;
}

export interface ModalParamsChooseIhiNumber {
  newlyInputtedIhiNumber: string;
  patientVerifyIhiResponseData: PatientVerifyIhiResponseData;
}

export interface ModalParamsUnableToVerifyIhi {
  newlyInputtedIhiNumber: string;
}

export type PatientIHISavePayload = UpdatePatientIhiBodyDto['patientIHI'];

export type PatientVerifyIhiResponseData = GetPatientIhiResponseDto['response'];

export type PatientVerifyIhiResponsePatientData = PatientVerifyIhiResponseData['patient'];

export type PatientProfileUpdatePayload = UpdatePatientIhiBodyDto['updateProfile'];

export type HandleUpdateIhiDetailsArgs = {
  patientIHISavePayload: PatientIHISavePayload;
  updateProfile?: PatientProfileUpdatePayload;
  reasonForUpdate?: string | null;
};

export const usePatientIhi = (args: UsePatientIHIProps) => {
  const { patientData, patientId, handleAlerts, setData, getPatientData } = args;
  const { verifyPatientIhi } = useVerifyPatientIhi();
  const { postPatientIhi } = usePostPatientIhi();
  const [modalDataVerifyIhiNumber, setModalDataVerifyIhiNumber] = useState<PatientVerifyIhiResponseData | null>(null);
  const [modalDataSelectMultipleFields, setModalDataSelectMultipleFields] =
    useState<ModalParamsSelectMultipleFields | null>(null);
  const [modalDataChooseIhiNumber, setModalDataChooseIhiNumber] = useState<ModalParamsChooseIhiNumber | null>(null);
  const [modalDataUnableToVerifyIhi, setModalDataUnableToVerifyIhi] = useState<ModalParamsUnableToVerifyIhi | null>(
    null
  );
  const [patientIhiNeedsUpdate, setPatientIhiNeedsUpdate] = useState(false);

  const updateVerifiedIhiWithModalIfNeeded = async (patientVerifyIhiResponseData: PatientVerifyIhiResponseData) => {
    setModalDataSelectMultipleFields({
      patientVerifyIhiResponseData
    });
  };

  const userProvidedIhiSave = async (ihi_number: string) => {
    if (ihi_number && ihi_number.trim().length === 0) {
      iziToast.error({
        title: 'Error saving IHI details. IHI cannot be empty',
        position: 'topRight'
      });
      return;
    }

    try {
      const responseData = await verifyPatientIhi({
        ihiNumber: ihi_number,
        patientId: Number(patientId)
      });

      if (typeof responseData === 'string' || !responseData?.response?.patient) {
        setModalDataUnableToVerifyIhi({
          newlyInputtedIhiNumber: ihi_number
        });
        return;
      }

      const isSameIhiVerified = getIHINumberFromUrl(responseData.response.patient?.ihiNumber) === ihi_number;
      if (isSameIhiVerified) {
        await updateVerifiedIhiWithModalIfNeeded(responseData.response);
      } else {
        setModalDataChooseIhiNumber({
          newlyInputtedIhiNumber: ihi_number,
          patientVerifyIhiResponseData: responseData.response
        });
      }
    } catch (_e) {
      handleAlerts({
        severity: 'error',
        message: 'IHI Error: An error has occurred while saving the Patients IHI details',
        open: true
      });
    }
  };

  const automatedIhiSave = async () => {
    try {
      const patientVerifyIhiResponseData = await verifyPatientIhi({
        ihiNumber: null,
        patientId: Number(patientId)
      });

      if (typeof patientVerifyIhiResponseData === 'string') {
        handleAlerts({
          severity: 'error',
          message: `IHI Error: ${patientVerifyIhiResponseData}`,
          open: true
        });
        return;
      }

      await updateVerifiedIhiWithModalIfNeeded(patientVerifyIhiResponseData.response);
    } catch (_e) {
      handleAlerts({
        severity: 'error',
        message: 'IHI Error: An error has occurred while saving the Patients IHI details',
        open: true
      });
    }
  };

  const updateIHIData = async () => {
    const ihiStatus = getIhiStatus(
      patientData.ihi_number,
      patientData.ihi_number_status,
      patientData.ihi_record_status
    );
    if (!patientIhiNeedsUpdate && ihiStatus === IhiStatus.UNVERIFIED) {
      iziToast.warning({
        title: 'IHI Warning!',
        message: 'User has either unverified or inactive IHI!',
        position: 'topRight'
      });
      return;
    }

    if (patientIhiNeedsUpdate) {
      //if user triggers ihi update
      await userProvidedIhiSave(patientData.ihi_number as string);
      setPatientIhiNeedsUpdate(false);
      return;
    }

    if (!patientData.ihi_number) {
      //if user does not trigger ihi update but user has no ihi set
      await automatedIhiSave();
      return;
    }

    return;
  };

  const handleUpdateIhiDetails = async (args: HandleUpdateIhiDetailsArgs) => {
    const { patientIHISavePayload, updateProfile, reasonForUpdate } = args;

    try {
      await postPatientIhi({
        patientId: Number(patientId),
        patientIHI: patientIHISavePayload,
        updateProfile,
        // Include a 'reasonForUpdate' into the payload if it exists
        ...(reasonForUpdate && { reasonForUpdate })
      });

      if (patientIHISavePayload.ihiStatus === null && patientIHISavePayload.ihiRecordStatus === null) {
        handleAlerts({
          severity: 'warning',
          message: "Patient's (Unverified) IHI successfully updated.",
          open: true
        });
      } else {
        handleAlerts({
          severity: 'success',
          message: "Patient's IHI successfully updated.",
          open: true
        });
      }

      handlePatientDataRefresh({ updatedFields: updateProfile, patientIHISavePayload });
    } catch (err) {
      let errorMessage = 'Failed to update patient IHI. Please try again later.';
      if (err instanceof Error) {
        errorMessage = err.message;
      }

      handleAlerts({
        severity: 'error',
        message: `IHI Error: ${errorMessage}`,
        open: true
      });
    }
  };

  const handlePatientDataRefresh = ({
    updatedFields,
    patientIHISavePayload
  }: {
    updatedFields: PatientProfileUpdatePayload;
    patientIHISavePayload: PatientIHISavePayload;
  }) => {
    setData({
      ...patientData,
      ...(updatedFields?.firstName && { FirstName: updatedFields?.firstName }),
      ...(updatedFields?.lastName && { LastName: updatedFields?.lastName }),
      ...(updatedFields?.medicareNumber && { medicareNumber: updatedFields?.medicareNumber }),
      ...(updatedFields?.medicareRefNumber && { medicare_ref_number: Number(updatedFields?.medicareRefNumber) }),
      ...(updatedFields?.dob && { DoB: updatedFields?.dob.toISOString() }),
      ...(updatedFields?.gender && { gender: updatedFields?.gender }),
      ihi_number: getIHINumberFromUrl(patientIHISavePayload?.patient.ihiNumber) ?? null,
      ihi_number_status: patientIHISavePayload?.ihiStatus,
      ihi_record_status: patientIHISavePayload?.ihiRecordStatus
    });

    if (updatedFields || patientIHISavePayload) {
      setPatientIhiNeedsUpdate(false);
    }

    // Refresh Patient Details data/contents
    if (getPatientData) {
      getPatientData();
    }
  };

  const handleCloseModalVerifyIhiNumber = () => {
    setModalDataVerifyIhiNumber(null);
  };

  const handleCloseModalSelectMultipleFields = () => {
    setModalDataSelectMultipleFields(null);
  };

  const handleCloseModalConfirmIhiNumber = () => {
    setModalDataChooseIhiNumber(null);
  };

  const handleCloseModalUnableToVerify = () => {
    setModalDataUnableToVerifyIhi(null);
  };

  return {
    modalDataVerifyIhiNumber,
    modalDataSelectMultipleFields,
    modalDataChooseIhiNumber,
    modalDataUnableToVerifyIhi,
    patientIhiNeedsUpdate,
    updateIHIData,
    setPatientIhiNeedsUpdate,
    handleUpdateIhiDetails,
    handleCloseModalVerifyIhiNumber,
    handleCloseModalSelectMultipleFields,
    handleCloseModalConfirmIhiNumber,
    handleCloseModalUnableToVerify
  };
};
