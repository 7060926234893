import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getConsultationDoubleBookings } from '@/api/consultations/getConsultationDoubleBookings';
import { QueryKey } from '@/data/query';

export const useGetConsultationDoubleBookings = () => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [QueryKey.Consultations],
    queryFn: () => getConsultationDoubleBookings(),
    placeholderData: keepPreviousData
  });

  return {
    data,
    isError,
    isLoading
  };
};
