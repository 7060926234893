import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import type { MenuButtonProps } from './MenuButton';
import { MenuButton } from './MenuButton';

interface MenuNavButtonProps extends MenuButtonProps {
  url: string;
  key: string | number;
  end: boolean;
  navRef?: React.RefObject<HTMLAnchorElement>;
}

const StyledNavLink = styled(NavLink)(() => ({
  width: '100%',
  height: 'auto',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '&.active': {
    backgroundColor: 'transparent'
  }
}));

export const MenuNavButton = ({
  key,
  end,
  name,
  icon,
  url,
  navRef,
  enableToolTip,
  isSideBarOpen
}: MenuNavButtonProps) => {
  return (
    <StyledNavLink key={key} end={end} to={url} ref={navRef} className={'navLink'}>
      {({ isActive }) => (
        <MenuButton
          icon={icon}
          name={name}
          isActive={isActive}
          isSideBarOpen={isSideBarOpen}
          enableToolTip={enableToolTip}
        />
      )}
    </StyledNavLink>
  );
};
