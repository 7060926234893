import { Button, styled } from '@mui/material';

import settings from '@/data/constants';
import type { CalendarData } from '@/hooks/rest/types';
import { clearParamDataFromLocalStorage, getParamDataFromLocalStorage } from '@/utils/calendar';

interface Props {
  gtmEventId?: string; //GTM event tracking do not change it.
}

const ConsultButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '1.5rem',
  margin: '2rem 0',
  color: theme.palette.common.white,
  padding: '8px 22px',
  fontSize: '0.95rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '0.75rem'
  },

  [theme.breakpoints.up('xl')]: {
    maxWidth: '90%'
  },

  fontWeight: 'lighter',
  width: '100%',
  alignSelf: 'center'
}));

const ConsultButtonVariantB = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: 'none',
  marginTop: '2rem',
  borderRadius: '4px',
  fontSize: '1rem',

  [theme.breakpoints.up('md')]: {
    width: '50%'
  }
}));

function appendSearchParams<T extends object>(object: T): (keyof T)[] {
  return Object.keys(object) as unknown as (keyof T)[];
}

export default function PreConsultButton({ gtmEventId }: Props) {
  const preConsultUrl = `${settings.preConsultUrl}${document.location.search}`;

  const handleOnclick = () => {
    const url = new URL(preConsultUrl);
    try {
      const paramData = getParamDataFromLocalStorage();

      appendSearchParams(paramData as CalendarData).forEach((key) => {
        const value = paramData?.[key];
        if (value) {
          const formattedKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
          url.searchParams.append(formattedKey, value);
        }
      });

      url.searchParams.append('email', paramData?.email || 'error-referencing-email');

      clearParamDataFromLocalStorage();
      window.open(url, '_blank');
    } catch (e) {
      console.log('pre-consult-button error - ', e);
      window.open(url, '_self');
    }
  };

  return (
    <>
      {/*
        TODO - Below code with `vwo class` is related to Payment flow - AB Test
        VWO experiment details here: https://app.vwo.com/#/test/ab/70/edit/pages/
        AB Test Ticket: https://montugroup.atlassian.net/browse/B2C-4673
        AB Test Removal Ticket: https://montugroup.atlassian.net/browse/B2C-4893
        */}
      <ConsultButton
        variant="contained"
        onClick={handleOnclick}
        id={gtmEventId}
        className="vwo-ab-test-control-variant"
      >
        Complete Pre-consult form
      </ConsultButton>
      <ConsultButtonVariantB
        variant="contained"
        onClick={handleOnclick}
        id={gtmEventId}
        className="vwo-ab-test-treatment-variant"
      >
        Complete pre-consultation form
      </ConsultButtonVariantB>
    </>
  );
}
