import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';

export interface AddAdditionalMedicationButtonProps {
  onClick: () => void;
  isDisabled: boolean;
}

export const AddAdditionalMedicationButton = ({ onClick, isDisabled }: AddAdditionalMedicationButtonProps) => {
  return (
    <Button
      variant="text"
      sx={{ justifyContent: 'flex-start', textTransform: 'none', width: 'fit-content', fontWeight: 'bold' }}
      color="warning"
      onClick={onClick}
      disabled={isDisabled}
      data-dd-privacy="allow"
      endIcon={<AddIcon />}
    >
      <Typography fontWeight="unset" data-dd-privacy="allow">
        Add additional medication
      </Typography>
    </Button>
  );
};
