import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormLabel, Grid, styled } from '@mui/material';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import type { z } from 'zod';
import { EditModeContext, withEditModeContextProvider } from '../../EditModeContextProvider';
import EditableSections from '../../EditableSections';
import { schema } from './ContactDetails.schema';

type FormFields = z.infer<typeof schema>;

type ContactDetailsProps = {
  editable?: boolean;
  defaultValues?: FormFields;
  onSubmit: (data: FormFields) => void;
  isEmailEditable?: boolean;
};

const StyledFormLabel = styled(FormLabel)({});

function ContactDetails({ editable = true, isEmailEditable = false, defaultValues, onSubmit }: ContactDetailsProps) {
  const { isEditMode } = useContext(EditModeContext);
  const formContext = useForm<FormFields>({
    values: defaultValues,
    resolver: zodResolver(schema)
  });
  return (
    <EditableSections title="Contact Details" editable={editable} formContext={formContext} actions={{ onSubmit }}>
      <FormContainer formContext={formContext}>
        <Box display="flex" width="100%" my={7}>
          <Box width="50%" pr={10}>
            <Grid container>
              <Grid item xs={4} alignSelf="center">
                <StyledFormLabel htmlFor="phone-textfield">Phone</StyledFormLabel>
              </Grid>
              <Grid item xs={8}>
                <TextFieldElement id="phone-textfield" name="phone" disabled={!isEditMode} fullWidth={true} />
              </Grid>
            </Grid>
          </Box>
          <Box width="50%">
            <Grid container>
              <Grid xs={4} alignSelf="center">
                <StyledFormLabel htmlFor="email-textfield">Email</StyledFormLabel>
              </Grid>
              <Grid xs={8}>
                <TextFieldElement
                  id="email-textfield"
                  name="email"
                  disabled={!isEditMode || !isEmailEditable}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormContainer>
    </EditableSections>
  );
}

export default withEditModeContextProvider(ContactDetails);
