import { datadogRum } from '@datadog/browser-rum';
import { CircularProgress, Stack, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useState } from 'react';
import { Provider as GraphQLProvider, cacheExchange, createClient, dedupExchange, fetchExchange } from 'urql';

import './api/pmsAxios';

import AppRoutes from './components/AppRoutes';
import settings from './data/constants';
import { getUser } from './data/service/authService';
import { StoreProvider } from './data/store';
import useTrackingProviders from './hooks/useTrackingProviders';
import theme from './theme';
import { LaunchDarkly } from './utils/launchdarkly';

import 'izitoast/dist/css/iziToast.min.css';
import './assets/scss/app.scss';

const THEME = createTheme(theme);

const client = createClient({
  url: settings.paymentGqlURL,
  exchanges: [dedupExchange, cacheExchange, fetchExchange]
});

const App = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const user = getUser();
  // Hook responsible for setting up all our tracking services. This can be expanded to include multiple services
  useTrackingProviders(settings);

  // Set up datadogRum user, so we can find users by userID in datadog
  // Safest and fastest implementation to solve for now
  if (
    user &&
    user.constructor === Object &&
    user.id !== undefined &&
    user.id !== null &&
    ['string', 'number'].includes(typeof user.id)
  ) {
    try {
      datadogRum.setUser({
        id: user.id
      });
      console.info('The user has been in Datadog RUM:', user.id);
    } catch (error) {
      console.error('Failed to set user in Datadog RUM:', error);
    }
  }

  // initialize new React Query instance
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1
      }
    }
  });

  // initialise LD instance
  useEffect(() => {
    setLoading(true);
    LaunchDarkly.init(user).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <GoogleOAuthProvider clientId={settings.googleLoginClientID}>
      <GraphQLProvider value={client}>
        <StoreProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={THEME}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <LaunchDarkly.Provider>
                  <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <AppRoutes />
                  </QueryClientProvider>
                </LaunchDarkly.Provider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </StoreProvider>
      </GraphQLProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
