import type { HandleUpdateIhiDetailsArgs } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/hooks/usePatientIhi';
import { FF_IHI_UNVERIFIED_IHI_MODAL_QUESTIONS } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { FeedbackRadioGroup } from '@/shared-ui';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert, Box, Button, Paper, Stack, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { blueGrey, brown } from '@mui/material/colors';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

export type ModalUnableToVerifyIhiProps = {
  unverifiedIhiNumber: string | null;
  handleUpdateIhiDetails: (args: HandleUpdateIhiDetailsArgs) => void;
  handleModalClose: () => void;
};

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll'
});

const StyledModalContents = styled(Paper)({
  padding: 24,
  width: 575,
  outline: 'none',
  color: blueGrey[900]
});

const StyledFeedbackContainer = styled(FeedbackRadioGroup)({
  '.MuiFormControlLabel-root': {
    padding: '5px'
  }
});

/**
 * ModalUnableToVerifyIhi
 *
 * This component renders a modal that informs the user that the IHI (Individual Healthcare Identifier) number cannot be verified.
 * It provides the user with the option to override the unverified IHI number.
 *
 * @param {ModalUnableToVerifyIhiProps} props - The properties object.
 * @param {string | null} props.unverifiedIhiNumber - The unverified IHI number.
 * @param {(args: HandleUpdateIhiDetailsArgs) => void} props.handleUpdateIhiDetails - Function to handle updating the IHI details.
 * @param {() => void} props.handleModalClose - Function to handle closing the modal.
 *
 * @returns {JSX.Element} The rendered component.
 */

export const ModalUnableToVerifyIhi = (props: ModalUnableToVerifyIhiProps) => {
  const [reasonForUpdate, setReasonForUpdate] = useState<string | null>(null);
  const { flags } = useFeatureFlags();
  const { unverifiedIhiNumber, handleUpdateIhiDetails, handleModalClose } = props;
  const ffIhiVerifiedIhiModalQuestions = flags[FF_IHI_UNVERIFIED_IHI_MODAL_QUESTIONS];
  const canSubmit = Boolean(reasonForUpdate) || !ffIhiVerifiedIhiModalQuestions;

  const handleUpdateSubmit = async () => {
    const updatedPatientDetailsInIHI = {
      ihiStatus: null,
      ihiRecordStatus: null,
      requestMessageID: null,
      responseMessageID: null,
      patient: {
        ihiNumber: unverifiedIhiNumber as string
      }
    };

    await handleUpdateIhiDetails({
      patientIHISavePayload: updatedPatientDetailsInIHI,
      reasonForUpdate
    });

    if (handleModalClose) {
      handleModalClose();
    }
  };

  const handleFeedbackOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReasonForUpdate(event.target.value);
  };

  return (
    <StyledModal open={true} closeAfterTransition>
      <StyledModalContents>
        <Box padding={'1rem'}>
          <Stack direction={'row'} alignItems={'baseline'} paddingBottom={5}>
            <Box paddingRight={4}>
              <ErrorOutlineIcon color={'error'} />
            </Box>
            <Typography variant={'h4'} color={brown[700]}>
              Unable to verify IHI Number
            </Typography>
          </Stack>
          <Box paddingBottom={8}>
            <Typography>We cannot verify the IHI number that you have entered.</Typography>
          </Box>
          <Alert severity={'info'}>
            <Typography>
              <strong>Note:</strong> Overriding the IHI number means that you are choosing to use an unverified IHI
              number. If the patient has confirmed the unverified IHI is correct, press confirm and request supporting
              documentation from the patient.
            </Typography>
          </Alert>
          {ffIhiVerifiedIhiModalQuestions && (
            <>
              <Box marginTop={10} marginBottom={5}>
                <Typography variant={'h6'} fontWeight={600}>
                  Why do you want to save the Unverified IHI number?
                </Typography>
                <Typography>We will use this data for future updates</Typography>
              </Box>
              <StyledFeedbackContainer
                onChange={handleFeedbackOnChange}
                options={[
                  {
                    label:
                      "I have confirmed the patient's details through other documentation, and I am confident the IHI number is accurate",
                    value: 'I have confirmed'
                  },
                  {
                    label:
                      'The patient verbally confirms this is their IHI number, I will request supporting documentation to formally verify it',
                    value: 'The patient confirms'
                  }
                ]}
              />
            </>
          )}
          <Stack direction={'row'} justifyContent={'end'} paddingTop={6}>
            <Box marginRight={5}>
              <Button onClick={handleModalClose} color="secondary" variant="text">
                <Typography>Cancel</Typography>
              </Button>
            </Box>
            <Tooltip
              arrow
              placement="top"
              title={
                !canSubmit ? (
                  <Typography fontSize={14}>
                    Please select a reason above for wanting to override with an Unverified IHI Number
                  </Typography>
                ) : null
              }
            >
              <span>
                <Button disabled={!canSubmit} onClick={handleUpdateSubmit} variant="contained" color="secondary">
                  <Typography>Yes, override</Typography>
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Box>
      </StyledModalContents>
    </StyledModal>
  );
};
