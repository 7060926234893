import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import iziToast from 'izitoast';
import moment from 'moment';

import { useAccessControl } from '@/hooks';
import { UserRole } from '@/types';

import settings from '../../data/constants';
import { getAuthData } from '../../data/service/authService';
import { makePUT } from '../../data/service/dataService';

const PREFIX = 'DocumentTable';

const classes = {
  table: `${PREFIX}-table`,
  Buttonlink: `${PREFIX}-Buttonlink`,
  linkDanger: `${PREFIX}-linkDanger`,
  ellipsis: `${PREFIX}-ellipsis`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.table}`]: {
    cursor: 'pointer'
  },

  [`& .${classes.Buttonlink}`]: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    color: theme.palette.secondary.main,
    background: 'none !important',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  [`& .${classes.linkDanger}`]: {
    color: `${theme.palette.orange} !important`
  },

  [`& .${classes.ellipsis}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const DocumentTable = (props) => {
  const { isDoc, token, isAdmin, isNurse, isManager, isSuperAdmin } = getAuthData();
  const { restrictAccess } = useAccessControl();

  const updateDocument = async (sendBody, deleted = false) => {
    const resp = await makePUT(`document`, sendBody, 'verifyDocument-DocumentTable');
    if (resp) {
      props.refresh();
      iziToast.success({
        title: deleted ? 'Document deleted' : 'Document status updated',
        position: 'topRight'
      });
    } else
      iziToast.error({
        title: deleted ? 'Failed to delete!' : 'Failed to Verify!',
        message: 'Please try again later.',
        position: 'topRight'
      });
  };

  const handleChange = (event, id) => {
    const checked = event.target.checked;
    const sendBody = {
      verified: checked,
      id
    };
    updateDocument(sendBody);
  };

  const deleteDocument = (e) => {
    iziToast.question({
      id: 'Delete question',
      timeout: false,
      displayMode: 1,
      zindex: 999,
      overlay: true,
      title: 'Confirm',
      message: 'Are you sure you want to delete the Document?',
      position: 'center',
      buttons: [
        [
          '<button><b>Yes</b></button>',
          function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'Yes');
          },
          true
        ],
        [
          '<button>Cancel</button>',
          function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'No');
          }
        ]
      ],
      onClosed: async (instance, toast, closedBy) => {
        if (closedBy === 'Yes') {
          const id = e.target.dataset.key;
          updateDocument({ active: false, id }, true);
        }
      }
    });
  };

  const downloadFile = async (e) => {
    e.preventDefault();
    const value = e.target.dataset.key;
    fetch(`${settings.url}/patientNote/download?key=${value}`, {
      method: 'GET',
      headers: new Headers({
        authorization: `Bearer ${token}`
      })
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = value;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const deleteDocumentColumnAccessId = restrictAccess([UserRole.Admin], <TableCell align="left">Delete</TableCell>);

  return (
    <Root>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Patient Document Table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50px', minWidth: '50px' }}>Document Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Formulation</TableCell>
              <TableCell align="left">Uploaded Date</TableCell>
              <TableCell align="left">Uploaded By</TableCell>
              <TableCell align="left">Verified</TableCell>
              <TableCell align="left">Verified Date</TableCell>
              {deleteDocumentColumnAccessId}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" sx={{ width: 50, minWidth: 50 }}>
                  <Tooltip title={row.document_path.split('/').pop()}>
                    <Box width="250px" className={classes.ellipsis}>
                      <Link onClick={downloadFile} data-key={row.document_path}>
                        {row.document_path.split('/').pop()}
                      </Link>
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">{row.DocumentType.name}</TableCell>
                <TableCell align="left">{row.ProductCategory?.name}</TableCell>
                <TableCell align="left">{row.ProductFormulation?.name}</TableCell>
                <TableCell align="left">{moment(row.upload_date).format('DD-MMM-YYYY')}</TableCell>
                <TableCell align="left">
                  {row.CreatedBy ? `${row.CreatedBy.first_name} ${row.CreatedBy.last_name}` : ''}
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={row.verified}
                    onChange={(e) => handleChange(e, row.id)}
                    disabled={!isDoc}
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.verified_date && moment(row.verified_date).format('DD-MMM-YYYY')}
                </TableCell>
                {(isSuperAdmin || isDoc || isNurse || isManager) && props.patientActive && (
                  <TableCell>
                    <Link
                      className={`${classes.Buttonlink} ${classes.linkDanger}`}
                      data-key={row.id}
                      onClick={deleteDocument}
                    >
                      Delete
                    </Link>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
};

export default DocumentTable;
