import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import iziToast from 'izitoast';
import moment from 'moment';
import React from 'react';

import { getToken } from '../../data/service/authService';
import { API_URL, makeGET } from '../../data/service/dataService';
import FileUpload from '../fields/FileUpload';
import InputField from '../fields/InputField';

const PREFIX = 'NoteDocuments';

const classes = {
  table: `${PREFIX}-table`
};

const StyledBox = styled(Box)({
  [`& .${classes.table}`]: {
    cursor: 'pointer'
  }
});

const NoteDocuments = (props) => {
  const initState = {
    type: '',
    fileName: '',
    fileError: '',
    originalFileName: ''
  };
  const [loading, setLoader] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [data, setData] = React.useState(initState);
  const [documentData, setDocumentData] = React.useState({ images: [], others: [] });

  const ffImmutableNotesActive = props.ffImmutableNotesActive;
  const newNote = !props.patientNote.id;

  const fileHandling = (e) => {
    const uploadedFile = e.target.files[0];
    const fileName = uploadedFile.name;
    const fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['png', 'jpg', 'jpeg'].indexOf(fileExtension) === -1) {
      setData({
        ...data,
        fileError: 'This File type is not allowed'
      });
      return;
    }
    if (uploadedFile && uploadedFile.size > 26214400) {
      setData({
        ...data,
        fileError: 'STATE File Exceeds Limit'
      });
    } else {
      setFile(uploadedFile ? uploadedFile : null);
      setData({
        ...data,
        fileError: '',
        originalFileName: uploadedFile ? uploadedFile.name : ''
      });
    }
  };

  const getNotes = async (id) => {
    const resp = await makeGET(`patientNote/${id}`, 'getDocumentsByNote-ConsultationTab');
    if (resp) {
      const images = [];
      const documents = [];
      for (const element of resp) {
        if (['jpg', 'jpeg', 'png'].includes(element.path.split('.').pop())) {
          const url = `/${element.path.split('.').slice(0, -1).join('.')}_thumb.jpg`;
          images.push({ ...element, url });
        } else {
          documents.push(element);
        }
      }
      setDocumentData({ others: documents, images });
    }
  };

  const loadDocuments = React.useCallback(async () => {
    if (props.patientNote.id) await getNotes(props.patientNote.id);
  }, [props.patientNote.id]);

  React.useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const onSumbit = async () => {
    if (props.disabled) {
      return;
    }
    const noteId = props.patientNote.id;
    if (!noteId && props.patientNote.heading === '') {
      iziToast.warning({ title: 'Error', message: 'Please have a Note Heading.' });
      return;
    }
    setLoader(true);
    const sendBody = new FormData();
    const filExtention = file.name.split('.').pop().toLowerCase();
    if (data.fileName) {
      sendBody.append('file_name', `${data.fileName}.${filExtention}`);
    }
    if (['jpg', 'jpeg', 'png'].includes(filExtention)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const canvas = await generateThumbnail(file, [200, 200]);
      sendBody.append('canvasFile', canvas);
    }
    sendBody.append('file', file);
    sendBody.append('patientId', props.patient_id);
    if (noteId) {
      sendBody.append('note_id', noteId);
    } else {
      sendBody.append('patientNote', JSON.stringify(props.patientNote));
    }
    const sendHeaders = new Headers();
    sendHeaders.append('Authorization', `Bearer ${getToken()}`);

    const requestOptions = {
      method: 'POST',
      headers: sendHeaders,
      body: sendBody,
      redirect: 'follow'
    };
    const resp = await fetch(`${API_URL}/patientNote/upload?patientID=${props.patient_id}`, requestOptions);
    if (resp.ok) {
      setLoader(false);
      iziToast.success({
        title: 'Document Uploaded',
        position: 'topRight'
      });
      setData(initState);
      setFile(null);
      if (noteId) {
        await getNotes(noteId);
      } else {
        const response = await resp.text();
        const Id = JSON.parse(response).note.id;
        props.updatePatientNote((prev) => ({ ...prev, id: Id }));
        await getNotes(Id);
      }
    } else {
      setLoader(false);
      iziToast.error({
        title: 'Failed to Upload!',
        message: 'Please try again later.',
        position: 'topRight'
      });
    }
  };

  return (
    <StyledBox>
      <Box display="flex" flexDirection="row-reverse" mb={2}>
        {file ? (
          <Box display="flex">
            <InputField
              label="File Name"
              id="Patient-document-Type-name"
              type="text"
              value={data.fileName}
              placeholder="-"
              labelPadding="7px 5px"
              customGrid={[3, 9]}
              helperText="Name for the uploaded file to be stored as in our servers. [The actual file name will be maintained if left empty]"
              onChange={(e) => setData((prev) => ({ ...prev, fileName: e.target.value }))}
            />
            <Box pt={0.5}>
              <Button variant="contained" color="secondary" onClick={onSumbit} disabled={props.disabled}>
                <Box width="120px">{loading ? <CircularProgress size={20} thickness={4} /> : 'Upload File'}</Box>
              </Button>
            </Box>
          </Box>
        ) : (
          ''
        )}
        {(newNote || !ffImmutableNotesActive) && (
          <Box display="flex" flexDirection="row-reverse">
            <FileUpload
              file={file}
              fileName={data.originalFileName}
              fileError={data.fileError}
              onChange={fileHandling}
              disabled={props.disabled}
              small
            />
          </Box>
        )}
      </Box>
      {documentData.images.length > 0 && (
        <Box sx={{ flexGrow: 1 }} mb={1}>
          <Grid container spacing={1}>
            {documentData.images.map((row) => {
              const fileName = row.path.split('/')[row.path.split('/').length - 1];
              return (
                <Grid
                  item
                  xs={3}
                  key={row.path}
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Link onClick={() => window.open(`/${row.path}`)}>
                    <img src={row.url} data-key={row.path} alt={fileName} height="inherit" />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
      {documentData.others.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Other Documents">
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell align="left">Uploaded Date</TableCell>
                <TableCell align="left">Uploaded By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentData.others.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Link onClick={() => window.open(`/${row.path}`)}>{row.path.split('/').pop()}</Link>
                  </TableCell>
                  <TableCell align="left">{moment(row.upload_date).format('DD-MMM-YYYY')}</TableCell>
                  <TableCell align="left">{row.uploaded_by}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </StyledBox>
  );
};

export default NoteDocuments;

const generateThumbnail = (file, boundBox) => {
  const reader = new FileReader();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    reader.onload = function (event) {
      var img = new Image();
      img.onload = function () {
        var scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height);
        let w = img.width * scaleRatio;
        let h = img.height * scaleRatio;
        canvas.width = w;
        canvas.height = h;
        ctx.drawImage(img, 0, 0, w, h);
        return resolve(canvas.toDataURL(file.type));
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
};
