import { UserRole } from '@/types';

import { DoctorScheduler } from '@/pages/DoctorScheduler';
import DassAssessmentScorePage from './components/DassAssessmentForm/DassAssessmentScorePage';
import PageNotFound from './pages/404';
import CardDetail from './pages/CardDetail';
import ConfirmConsultation from './pages/ConfirmConsultation';
import ConsultationDoubleBookings from './pages/ConsultationDoubleBookings';
import Consultations from './pages/Consultations';
import DassAssessmentForm from './pages/DassAssessmentForm';
import DoctorCalendar from './pages/DoctorCalendar';
import Doctors from './pages/Doctors';
import DoctorScriptReview from './pages/DoctorsScriptReview';
import Login from './pages/Login';
import NurseCalendar from './pages/NurseCalendar';
import NurseScheduler from './pages/NurseScheduler';
import Nurses from './pages/Nurses';
import OnBoardDoctor from './pages/OnboardDoctor';
import PatientV2 from './pages/PatientDetailsV2';
import Patients from './pages/Patients';
import Prescriptions from './pages/Prescriptions';
import ProductCatalog from './pages/Product-catalog';
import RoundRobinDoctors from './pages/RoundRobinDoctors';
import RoundRobinNurses from './pages/RoundRobinNurses';
import SupportSelfService from './pages/SupportSelfService';
import OnBoardNurse from './pages/onboardNurse';
import OnBoardPatient from './pages/onboardPatient';
import PatientConsentForm from './pages/patientConsent';
import ProductOffering from './pages/productOffering';
import pubLogin from './pages/pubLogin';
import ViewImage from './pages/viewImage';

export type RouteType = {
  path: string;
  component: React.FunctionComponent;
  isPrivate: boolean;
  navBar?: boolean;
  restrictAccess?: UserRole[];
};

export const routes: RouteType[] = [
  {
    path: '/',
    component: Login,
    isPrivate: false,
    navBar: true
  },
  {
    path: '/8C4yKU8CEa6pb',
    component: pubLogin,
    isPrivate: false,
    navBar: true
  },
  {
    path: '/confirm-consultation',
    component: ConfirmConsultation,
    isPrivate: false,
    navBar: false
  },
  {
    path: '/card-detail',
    component: CardDetail,
    isPrivate: false,
    navBar: false
  },
  {
    path: '/payment/success',
    component: CardDetail,
    isPrivate: false,
    navBar: false
  },
  {
    path: '/payment/failure',
    component: CardDetail,
    isPrivate: false,
    navBar: false
  },
  {
    path: '/patients/:id',
    component: PatientV2,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/nurses',
    component: Nurses,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/nurse-calendar',
    component: NurseCalendar,
    isPrivate: true,
    restrictAccess: [UserRole.Doctor],
    navBar: true
  },
  {
    path: '/nurse-scheduler',
    component: NurseScheduler,
    isPrivate: true,
    restrictAccess: [UserRole.Doctor],
    navBar: true
  },
  {
    path: '/doctor-scheduler',
    component: DoctorScheduler,
    isPrivate: true,
    restrictAccess: [UserRole.Doctor],
    navBar: true
  },
  {
    path: '/calendar',
    component: DoctorCalendar,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/patients/:id?',
    component: Patients,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/prescriptions',
    component: Prescriptions,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/consultations',
    component: Consultations,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/doctors',
    component: Doctors,
    isPrivate: true,
    restrictAccess: [UserRole.Doctor],
    navBar: true
  },
  {
    path: '/patients/onboard/new',
    component: OnBoardPatient,
    isPrivate: true,
    restrictAccess: [UserRole.Admin, UserRole.Doctor],
    navBar: true
  },
  {
    path: '/doctors/onboard/new',
    component: OnBoardDoctor,
    isPrivate: true,
    restrictAccess: [UserRole.Admin, UserRole.Nurse, UserRole.Doctor],
    navBar: true
  },
  {
    path: '/doctors/rescript',
    component: DoctorScriptReview,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/viewImage/:key?',
    component: ViewImage,
    isPrivate: true
  },
  {
    path: '/Product-catalog',
    component: ProductCatalog,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/product-detail/:id',
    component: ProductOffering,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/onboard-new-nurse',
    component: OnBoardNurse,
    isPrivate: true,
    restrictAccess: [UserRole.Doctor, UserRole.Patient, UserRole.Nurse, UserRole.Admin],
    navBar: true
  },
  {
    path: '/dash-assessment-form/:patient_id/:uuid',
    component: DassAssessmentForm,
    isPrivate: false,
    navBar: false
  },
  {
    path: '/dash-assessment-score/:patient_id',
    component: DassAssessmentScorePage,
    isPrivate: false,
    navBar: false
  },
  {
    path: '/consent-form/:patientId/:uuid',
    component: PatientConsentForm,
    isPrivate: false,
    navBar: false
  },
  {
    path: '/roundrobin-doctors',
    component: RoundRobinDoctors,
    isPrivate: true,
    restrictAccess: [UserRole.Doctor, UserRole.Patient, UserRole.Nurse, UserRole.Admin],
    navBar: true
  },
  {
    path: '/roundrobin-nurses',
    component: RoundRobinNurses,
    isPrivate: true,
    navBar: true
  },
  {
    path: '/support-self-service',
    component: SupportSelfService,
    isPrivate: true,
    // We want to whitelist only to the roles: UserRole.SuperAdmin, UserRole.Admin
    restrictAccess: [UserRole.Doctor, UserRole.Patient],
    navBar: true
  },
  {
    path: '/consultation-double-bookings',
    component: ConsultationDoubleBookings,
    isPrivate: true,
    restrictAccess: [UserRole.Doctor, UserRole.Patient],
    navBar: true
  },
  {
    path: '/*',
    component: PageNotFound,
    isPrivate: false,
    navBar: true
  }
];
