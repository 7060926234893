import { Alert, FormGroup, Link, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DateField } from '@mui/x-date-pickers';
import moment from 'moment';
import type { ChangeEvent, FormEvent } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';

import AppointmentSummary from '@/components/paymentCard/components/AppointmentSummary';
import { InitialisingContext } from '@/components/paymentCard/components/CreditCardPayment';
import type { EwaySecureFieldEvent } from '@/components/paymentCard/components/EwayForm';
import EwayForm from '@/components/paymentCard/components/EwayForm';
import { FF_CONCESSION_DISCOUNT_RATE, FF_ENABLE_DISCOUNT_CODES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import type { DiscountType } from '@/hooks/rest/useDiscountCode';
import useDiscountCode, { DiscountCodeStatus } from '@/hooks/rest/useDiscountCode';
import { SROnly } from '@/shared-ui/SROnly/SROnly';
import { GenericErrorCause } from '@/types/payments.types';

import { PaymentBookingConfirmed } from '@/components/paymentCard/components/PaymentBookingConfirmed';
import type { CalendarData } from '@/hooks/rest/types';
import AddPromoCode from './AddPromoCode';
import PaymentFormSkeleton from './PaymentFormSkeleton';

enum ConcessionType {
  HealthCare = 'Health Care Card',
  Vetarans = 'Veterans Card (DVA)',
  Senoirs = 'Seniors Health Concession Card',
  Pensioner = 'Pensioner Concession Card',
  None = ''
}

export type Payload = {
  cardHolder?: string;
  expiry?: moment.Moment;
  secureFieldCode?: string;
  isConcession?: boolean;
  discountCode?: string;
};

type PaymentFormProps = {
  data: CalendarData;
  isPaymentTrackingTriggered: {
    current: boolean;
  };
  onSubmit: (payload: Payload) => void;
  setCreditCardError: (error: string) => void;
  setErrorMessages: (message: string[]) => void;
};

const StyledHostedInput = styled(Box)({
  height: '3.9rem'
});

const Form = styled('form')({
  marginTop: '1rem',
  width: '100%'
});

export const StyledTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '1.3rem',
  marginBottom: '1rem'
}) as typeof Typography;

const StyledTextField = styled(TextField)({
  marginBottom: '0.5rem'
});

type DataType<T> = T | null;

export default function PaymentForm({
  data,
  isPaymentTrackingTriggered,
  onSubmit,
  setErrorMessages,
  setCreditCardError
}: PaymentFormProps) {
  const { flags } = useFeatureFlags();
  const ffEnableDiscountCodes = flags[FF_ENABLE_DISCOUNT_CODES];
  const ffConcessionRate = flags[FF_CONCESSION_DISCOUNT_RATE];
  const enableEwayPayments = !!data.isEwayPayment;

  const discountCodeFromParams = data?.promocode;
  const {
    loading: discountCodeLoading,
    data: discount,
    doGet: getDiscount
  } = useDiscountCode(discountCodeFromParams as string);
  const { initialising } = useContext(InitialisingContext);

  const [cardHolder, setCardHolder] = useState('');
  const [secureFieldCode, setSecureFieldCode] = useState('');
  const [expiry, setExpiry] = useState<moment.Moment>();

  const [isConcession, setIsConcession] = useState(false);
  const [concessionType, setConcessionType] = useState<string>(ConcessionType.None);

  const [isEwayFormValid, setIsEwayFormValid] = useState(false);
  const [ewayFormErrors, setEwayFormErrors] = useState<{ [key: string]: string }>();

  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [showTCValidationMessage, setShowTCValidationMessage] = useState(false);

  const isDiscountCodeValid = discount?.status === DiscountCodeStatus.valid;
  const discountCodeValue = isDiscountCodeValid ? discount?.discountCode : undefined;

  const appendPromocodetoLocalStorage = useCallback(() => {
    const trackingConsultationSummary = JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}');
    trackingConsultationSummary.promocode = discountCodeValue;
    localStorage.setItem('tracking_consultation_summary', JSON.stringify(trackingConsultationSummary));
  }, [discountCodeValue]);

  useEffect(() => {
    setIsEwayFormValid(JSON.stringify(ewayFormErrors) === '{}');
  }, [ewayFormErrors]);

  useEffect(() => {
    if (discount?.status === DiscountCodeStatus.valid) {
      appendPromocodetoLocalStorage();
    }
  }, [appendPromocodetoLocalStorage, discount]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsConcession(event.target.checked);
  };

  const handleConcessionType = (event: SelectChangeEvent) => {
    setConcessionType(event.target.value);
  };

  const handleTermsConditionsChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setTermsConditionsChecked(event.target.checked);
    setShowTCValidationMessage(false);
  };

  function handleOnSubmit(event: FormEvent) {
    event.preventDefault();

    if (enableEwayPayments) {
      if (discount?.status === DiscountCodeStatus.valid && !termsConditionsChecked) {
        setShowTCValidationMessage(true);
        return;
      }

      if (secureFieldCode && isEwayFormValid) {
        onSubmit({
          secureFieldCode,
          discountCode: discountCodeValue,
          isConcession
        });
      } else {
        setCreditCardError(GenericErrorCause.INVALID_DETAILS);
        setShowTCValidationMessage(false);

        if (JSON.stringify(ewayFormErrors) !== '{}') {
          setErrorMessages(Object.values(ewayFormErrors || {}));
        }
      }
      return;
    }
    if (cardHolder && expiry) {
      onSubmit({ cardHolder, expiry });
    }
  }

  function handleDiscountApply(discountCode?: string) {
    if (!termsConditionsChecked) {
      setShowTCValidationMessage(true);
    }

    if (discountCode && termsConditionsChecked) {
      getDiscount({
        params: { discountCode }
      });
    }
  }

  function handleSecureFieldCallback(event: EwaySecureFieldEvent) {
    if (!event.valueIsValid) {
      setEwayFormErrors((prev) => ({ ...prev, [event.targetField]: `${event.targetField} is invalid.` }));
    } else {
      setEwayFormErrors((prev) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { [event.targetField]: _, ...rest } = prev || {};
        return rest;
      });
    }

    if (secureFieldCode !== event.secureFieldCode) {
      setSecureFieldCode(event.secureFieldCode);
    }
  }

  const fullName = data?.fullName || `${data?.firstName || ''} ${data?.lastName || ''}`.trim();
  return (
    <Form id="payment_form" method="POST" onSubmit={handleOnSubmit}>
      <Box display="grid" gridTemplateColumns={{ md: '1fr 1fr' }} gap={6}>
        <Box sx={{ gridRow: { xs: '1', md: '1/3' } }}>
          {initialising && <PaymentFormSkeleton enableEwayPayments={enableEwayPayments} />}
          <Box display={initialising ? 'none' : 'block'} paddingTop={{ xs: enableEwayPayments ? '100px' : 0, sm: 0 }}>
            {/*
            TODO - Below code with `vwo class` is related to Payment flow - AB Test
            VWO experiment details here: https://app.vwo.com/#/test/ab/70/edit/pages/
            AB Test Ticket: https://montugroup.atlassian.net/browse/B2C-4671
            AB Test Removal Ticket: https://montugroup.atlassian.net/browse/B2C-4892
            */}
            <StyledTypography component="h3" className={'vwo-ab-test-control-variant'}>
              {enableEwayPayments ? 'Payment Details' : 'Card Details'}
            </StyledTypography>
            <Box className={'vwo-ab-test-treatment-variant'}>
              <PaymentBookingConfirmed email={data?.email} />
            </Box>
            <fieldset style={{ border: 'none', margin: '0', padding: '0' }}>
              <SROnly as="legend">Payment Form</SROnly>
              {enableEwayPayments ? (
                <Box>
                  <EwayForm
                    fullName={fullName}
                    data={data}
                    secureFieldCallbackFn={handleSecureFieldCallback}
                  ></EwayForm>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  {/*
                    TODO - Below code with `vwo class` is related to Payment flow - AB Test
                    VWO experiment details here: https://app.vwo.com/#/test/ab/70/edit/pages/
                    AB Test Ticket: https://montugroup.atlassian.net/browse/B2C-4671
                    AB Test Removal Ticket: https://montugroup.atlassian.net/browse/B2C-4892
                   */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    mb={8}
                    className={'vwo-ab-test-control-variant'}
                  >
                    <StyledTextField
                      type="text"
                      variant="filled"
                      label="Full name"
                      InputProps={{
                        readOnly: true
                      }}
                      defaultValue={fullName}
                    />
                    <StyledTextField
                      type="text"
                      variant="filled"
                      label="Email"
                      InputProps={{
                        readOnly: true
                      }}
                      defaultValue={data?.email}
                    />
                  </Box>
                  {/*
                    TODO - Below code with `vwo class` is related to Payment flow - AB Test
                    VWO experiment details here: https://app.vwo.com/#/test/ab/70/edit/pages/
                    AB Test Ticket: https://montugroup.atlassian.net/browse/B2C-4671
                    AB Test Removal Ticket: https://montugroup.atlassian.net/browse/B2C-4892
                   */}
                  <StyledTypography component="h3" className={'vwo-ab-test-control-variant'}>
                    Card Details
                  </StyledTypography>
                  <StyledTypography component="h3" className={'vwo-ab-test-treatment-variant'}>
                    Payment Details
                  </StyledTypography>
                  <StyledHostedInput id="cardNumber"></StyledHostedInput>

                  <Box display="flex" justifyContent="space-between" gap={8}>
                    <DateField
                      format="MM/YY"
                      variant="filled"
                      required
                      minDate={moment()}
                      onChange={(val) => val && setExpiry(val)}
                      sx={{ flexBasis: '50%' }}
                    />
                    <StyledHostedInput id="cvv" sx={{ flexBasis: '50%' }}></StyledHostedInput>
                  </Box>

                  <StyledTextField
                    placeholder="Name on card"
                    required
                    variant="filled"
                    fullWidth
                    onChange={(e) => setCardHolder(e.target.value)}
                  />
                </Box>
              )}
            </fieldset>
          </Box>

          {enableEwayPayments && (
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isConcession} onChange={handleCheckboxChange} color="warning" />}
                  label="I have a valid Concession card"
                />
              </FormGroup>
              <Box paddingLeft={8}>
                <Typography component="small" variant="body2">
                  Concession Card holders receive a 10% discount on their consultation
                </Typography>
              </Box>
              {isConcession && (
                <Box display="flex" flexDirection="column" justifyContent="space-between" gap={8} paddingTop={8}>
                  <FormControl fullWidth>
                    <InputLabel>Concession Card Type</InputLabel>
                    <Select variant={'filled'} value={concessionType} defaultValue={''} onChange={handleConcessionType}>
                      {Object.values(ConcessionType)
                        .filter((value) => value !== ConcessionType.None)
                        .map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <Alert variant="outlined" severity="info" sx={{ marginBottom: 8 }}>
                    Your Concession Card discount has been automatically applied. Please bring your card to your nurse
                    appointment for verification.
                  </Alert>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box sx={{ order: { sx: 1, md: 2 } }}>
          <AppointmentSummary
            data={data}
            discount={
              enableEwayPayments && isConcession && concessionType !== ConcessionType.None
                ? ({
                    status: DiscountCodeStatus.valid,
                    discountPercent: ffConcessionRate,
                    discountCode: 'CONCESSION CARD'
                  } as DataType<DiscountType>)
                : discount
            }
            isPaymentTrackingTriggered={isPaymentTrackingTriggered}
          />
          {ffEnableDiscountCodes && (
            <Box mt={5}>
              <AddPromoCode
                isLoading={discountCodeLoading}
                value={discountCodeFromParams as string}
                handleDiscountApply={handleDiscountApply}
                status={discount?.status}
                handleTermsConditionsChecked={handleTermsConditionsChecked}
                showTCValidationMessage={showTCValidationMessage}
              />
            </Box>
          )}
          {enableEwayPayments ? (
            <>
              <Box sx={{ gridRow: { md: '2' } }}>
                <Button type="submit" variant="contained" sx={{ width: '100%', my: 4, py: 3 }}>
                  Submit Payment
                </Button>
              </Box>
              <Typography my={3}>
                By continuing to payment, you are agreeing to{' '}
                <Link href="https://www.alternaleaf.com.au/payment-terms" target="_blank">
                  Terms and Conditions{' '}
                </Link>
                , and accepting the terms of{' '}
                <Link href="https://www.alternaleaf.com.au/privacy" target="_blank">
                  Privacy Policy
                </Link>
                .
              </Typography>
            </>
          ) : (
            <Box>
              <Box sx={{ gridRow: { md: '2' }, mt: 5 }} className="vwo-ab-test-control-variant">
                <Button type="submit" variant="contained" sx={{ width: '100%', mb: 4 }}>
                  Save payment details
                </Button>
              </Box>
              {/*
                TODO - Below code with `vwo class` is related to Payment flow - AB Test
                VWO experiment details here: https://app.vwo.com/#/test/ab/70/edit/pages/
                AB Test Ticket: https://montugroup.atlassian.net/browse/B2C-4671
                AB Test Removal Ticket: https://montugroup.atlassian.net/browse/B2C-4892
               */}
              <Box sx={{ gridRow: { md: '2' }, mt: 5 }} className="vwo-ab-test-treatment-variant">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: '100%', mb: 4 }}
                  style={{ textTransform: 'none', fontSize: '1rem' }}
                >
                  Save payment details
                </Button>
                <Typography align={'center'} mb={10}>
                  You will not be charged yet
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Form>
  );
}
