import { WithSnowplow } from '@montugroup/data-collection';
import TabContext from '@mui/lab/TabContext';
import { styled, useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import NurseFlexCalendar from '@/components/NurseFlexCalendar/NurseFlexCalendar';
import { TimelineSchedulerNurses } from '@/components/calendar/TimelineScheduler/TimelineSchedulerNurses';
import { FF_ENABLE_SNOWPLOW } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useGetNurses } from '@/hooks/rest/useGetNurses';
import { Tab } from '@/shared-ui/Tabs/Tab';
import { TabPanel } from '@/shared-ui/Tabs/TabPanel';
import { Tabs } from '@/shared-ui/Tabs/Tabs';
import theme from '@/theme';

const StyledAppBarContainer = styled(Box)({
  width: 500,

  '.MuiAppBar-root': {
    boxShadow: 'none'
  }
});

export const NurseScheduler = () => {
  const [tabValue, setTabValue] = useState<number>(1);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { flags } = useFeatureFlags();
  const { nurses, isLoading: isLoadingGetNurses, isError: isErrorGetNurses } = useGetNurses();

  const ffEnableSnowplow = flags[FF_ENABLE_SNOWPLOW];

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const nurseScheduler = !isErrorGetNurses ? (
    <TimelineSchedulerNurses nurses={nurses} />
  ) : (
    <Box m={10}>
      <Alert severity="error">{isErrorGetNurses.toString()}</Alert>
    </Box>
  );

  return WithSnowplow(
    <Box component="div" mt={4}>
      <Box paddingX={4} mt={3}>
        <TabContext value={'Nurse Scheduler'}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h5'}>Nurse Scheduler</Typography>
            <StyledAppBarContainer>
              <AppBar position="static" color="transparent">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  centered
                  variant={isSmallerScreen ? 'scrollable' : 'fullWidth'}
                  scrollButtons="auto"
                >
                  <Tab label="Nurse Scheduler" />
                  <Tab label="Leave Management Planner" />
                </Tabs>
              </AppBar>
            </StyledAppBarContainer>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {nurseScheduler}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <NurseFlexCalendar nurses={nurses} nurseListLoading={isLoadingGetNurses} />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>,
    {
      appId: 'pms',
      trackerName: 'poc-3'
    },
    ffEnableSnowplow
  );
};

export default NurseScheduler;
