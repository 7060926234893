import { Box, Breadcrumbs, Chip, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getParamDataFromLocalStorage, getParamDataFromUrl, saveParamDataToLocalStorage } from '@/utils/calendar';

import { PaymentFlowBreadcrumb } from '@/components/paymentCard/components/PaymentFlowBreadcrumb';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Alternaleaf from '../components/branding/Alternaleaf';
import PaymentCard, { PaymentCardState } from '../components/paymentCard/PaymentCard';
import { brazeChangeUser, brazeTrackEvent, brazeUpdateUserAttributesFromPaymentPage } from '../services/braze.service';

const StyledTypography = styled(Typography)({
  color: 'text.primary',
  display: 'flex',
  alignItems: 'center'
});

const BreadCrumbsContainer = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

export default function CardDetail() {
  const [paymentCardState, setPaymentCardState] = useState<number | undefined>();
  let paramData = getParamDataFromUrl();

  function StepsBreadCrumb({ children }: { children: React.ReactNode }) {
    return (
      <Breadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            fontSize: '1.5rem',
            lineHeight: '1rem'
          }
        }}
        separator="-"
        aria-label="breadcrumb"
      >
        {children}
      </Breadcrumbs>
    );
  }

  function CheckCircle({ text }: { text: string }) {
    return (
      <StyledTypography>
        <CheckCircleIcon color="success" sx={{ height: '2.4rem', width: '2.4rem', mr: 1.5 }} fontSize="inherit" />
        {text}
      </StyledTypography>
    );
  }

  function NumberCircle({ text, number }: { text: string; number: number }) {
    return (
      <StyledTypography>
        <Chip
          component="span"
          size="small"
          sx={{ height: '2rem', width: '2rem', mr: 1.5, color: 'white', bgcolor: 'text.secondary' }}
          label={number}
        />
        {text}
      </StyledTypography>
    );
  }

  // If there's a missing UUID, try pulling existing data from local storage
  if (!paramData.inviteeUuid) {
    const localVal = getParamDataFromLocalStorage();
    // Only mutate if an entry exists
    if (localVal) {
      paramData = localVal;
    }
  }

  // update the entry in local storage for mixpanel
  useEffect(() => {
    if (paramData.inviteeUuid) {
      saveParamDataToLocalStorage(paramData);
    }
  }, [paramData]);

  // track and send events to braze
  useEffect(() => {
    if (paramData.email) {
      brazeChangeUser(paramData.email);
      brazeTrackEvent('consult-payment-page-loaded', paramData);
      brazeUpdateUserAttributesFromPaymentPage(paramData);
    }
  }, [paramData]);

  const location = useLocation();
  const isSuccessPage = paymentCardState === PaymentCardState.Success;

  useEffect(() => {
    const isSuccess = window.location.href.includes('success');
    const isFailure = window.location.href.includes('failure');
    const fallBackState = isFailure ? PaymentCardState.Fail : PaymentCardState.Input;
    setPaymentCardState(isSuccess ? PaymentCardState.Success : fallBackState);
  }, [location]);

  const controlAbTestContent = (
    <BreadCrumbsContainer item xs={12} marginBottom={8} marginLeft={3} data-testid={'payment-breadcrumb-control'}>
      <StepsBreadCrumb>
        <CheckCircle text="Pre-screen" />
        <CheckCircle text="Booking" />
        {isSuccessPage ? <CheckCircle text="Payment Details" /> : <NumberCircle text="Payment Details" number={3} />}
        <NumberCircle text="Pre-consult" number={4} />
      </StepsBreadCrumb>
    </BreadCrumbsContainer>
  );

  const treatmentAbTestContent = <PaymentFlowBreadcrumb stepNumber={isSuccessPage ? 4 : 3} />;

  return (
    <Alternaleaf>
      <Box>
        <Grid container spacing={3}>
          {/*
          TODO - Below code with `vwo class` is related to Payment flow - AB Test
          VWO experiment details here: https://app.vwo.com/#/test/ab/70/edit/pages/
          AB Test Ticket: https://montugroup.atlassian.net/browse/B2C-4671
          AB Test Removal Ticket: https://montugroup.atlassian.net/browse/B2C-4892
         */}
          <Box className={'vwo-ab-test-control-variant'}>{controlAbTestContent}</Box>
          <Box className={'vwo-ab-test-treatment-variant'} width={'100%'}>
            {treatmentAbTestContent}
          </Box>
          <PaymentCard data={paramData} state={paymentCardState} />
        </Grid>
      </Box>
    </Alternaleaf>
  );
}
